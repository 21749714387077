import React from 'react';
import { FaSpinner } from 'react-icons/fa';
import './Loading.css';

export const Loading = () => {
  return (
    <div className="flex flex-col justify-center items-center min-h-screen bg-transparent backdrop-blur-md">
      <FaSpinner className="text-blue-500 text-6xl animate-spin" />
      <p className="mt-4 text-lg text-blue font-semibold uppercase tracking-wider animate-pulse">
        Loading, please wait...
      </p>
    </div>
  );
};
